<template>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>User Group</label>
          <select class="form-control" v-model="price.user_group_id">
            <template v-for="(role, i) in roles">
              <option :value="role.id" :key="`role-${i}`">{{
                role.name
              }}</option>
            </template>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Lower Bound</label>
          <input
            type="text"
            required
            v-model="price.lower_bound"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Upper Bound</label>
          <input
            type="text"
            required
            v-model="price.upper_bound"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Charge Type</label>
          <select v-model="price.charge_type" class="form-control">
            <option value="fixed">Fixed</option>
            <option value="percent">Percentage</option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Cost</label>
          <input
            type="text"
            required
            v-model="price.cost"
            class="form-control"
          />
        </div>
      </div>
      <div class="col-md-12 text-right">
        <button class="btn btn-secondary">
          <i class="lni-save mr-2"></i> Save
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      price: {},
      error: "",
      roles: [],
    };
  },

  methods: {
    fetch() {
      this.$axios.get("/api/v1/dashboard/roles").then((response) => {
        this.roles = response.data.roles;
      });
    },

    submit() {
      this.$axios
        .post("/api/v1/dashboard/bidbond-pricing", this.price)
        .then(() => {
          this.$emit('close')
        })
        .catch((error) => {
          this.$http(error.response);
        });
    },
  },
};
</script>
